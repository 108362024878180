// var $ = require("jquery");
// var bootstrap = require('bootstrap')
// var Barba = require("barba.js");
import $ from 'jquery';
import Barba from 'barba';
// import 'bootstrap/js/dist/util';
// import 'bootstrap/js/src/util';
// import 'bootstrap/js/src/collapse';
// import 'bootstrap/js/dist/dropdown';
import { Util, Modal } from 'bootstrap';
// import 'bootstrap/js/dist/tooltip';
import '../scss/compiler.scss';
import slick from 'slick-carousel';
import jsSocials from 'jssocials';
import Zooming from 'zooming';
import * as imagesLoaded from 'imagesloaded';

const debug = false

export class Core {

    constructor($context) {
        this.components = new Array

        this.initPage($context)
    }

    initPage($context) {
        if (debug) console.log('C&H Creations initPage')
        if (debug) console.log(this.components)

        $(document).ready(() => {
            $("body").removeClass("preload");

            Barba.Pjax.start()

            this.setBiggerLink($context)
            this.setScrollAnimation($context)

            this.setDataComponent($context)

            // Barba.Prefetch.init()

            this.setBarbaTransition()

            imagesLoaded(document.querySelector('body'), (instance) => {
                setTimeout(() => {
                    $('#init-loader').fadeOut(1000)
                }, 2000)
            })

            Barba.Dispatcher.on('newPageReady', (currentStatus, oldStatus, container) => {
                let $newContext = $(container)

                if (debug) console.log('$newContext', $newContext)

                this.setBiggerLink($newContext)

                this.setDataComponent($newContext)
                $('body').removeClass('mobile-nav-open')
            })

            Barba.Dispatcher.on('linkClicked', () => {
                $(window).off('scroll')
            })

            Barba.Dispatcher.on('transitionCompleted', () => {
                this.setScrollAnimation($context)
            })

            Barba.Dispatcher.on('initStateChange', function () {
                if (typeof ga === 'function') {
                    ga('send', 'pageview', location.pathname);
                }
            })
        })
    }

    setBarbaTransition() {
        var FadeTransition = Barba.BaseTransition.extend({
            start: function () {
                /**
                 * This function is automatically called as soon the Transition starts
                 * this.newContainerLoading is a Promise for the loading of the new container
                 * (Barba.js also comes with an handy Promise polyfill!)
                 */

                // As soon the loading is finished and the old page is faded out, let's fade the new page
                Promise
                    .all([this.newContainerLoading, this.fadeOut()])
                    .then(this.fadeIn.bind(this));
            },
            fadeOut: function () {
                /**
                 * this.oldContainer is the HTMLElement of the old Container
                 */
                $("html, body").animate({ scrollTop: 0 }, "slow");

                return $(this.oldContainer).animate({ opacity: 0 }).promise();
            },
            fadeIn: function () {
                /**
                 * this.newContainer is the HTMLElement of the new Container
                 * At this stage newContainer is on the DOM (inside our #barba-container and with visibility: hidden)
                 * Please note, newContainer is available just after newContainerLoading is resolved!
                 */

                var _this = this;
                var $el = $(this.newContainer);

                $(this.oldContainer).hide();

                $el.css({
                    visibility: 'visible',
                    opacity: 0
                });

                $el.animate({ opacity: 1 }, 400, function () {
                    /**
                     * Do not forget to call .done() as soon your transition is finished!
                     * .done() will automatically remove from the DOM the old Container
                     */

                    _this.done();
                });
            }
        });

        /**
         * Next step, you have to tell Barba to use the new Transition
         */

        Barba.Pjax.getTransition = function () {
            /**
             * Here you can use your own logic!
             * For example you can use different Transition based on the current page or link...
             */

            return FadeTransition;
        }
    }

    setBiggerLink($context) {
        if (debug) console.log('setBiggerLink')

        $context.find('[data-bigger-link]').each(function (i, el) {
            var $target = $(el)

            if ($target.find('a[href]').length) {
                var $link = $target.find('a[href]')

                if ($link.attr('href').length) {
                    let link = $link.attr('href')
                    $target.css('cursor', 'pointer')

                    $target.on('click', function (e) {
                        e.preventDefault()
                        e.stopPropagation()
                        // window.location.href = link 

                        Barba.Pjax.goTo(link)
                    })
                }
            }
        })
    }

    setScrollAnimation($context) {
        const debug = false

        if (debug) console.log('setScrollAnimation')

        if ($(window).width() > 1024) {
            if ($context.find('[data-scroll-animation]').length) {
                const TRANSLATEY = 200

                let windowInfo = {
                    height: $(window).height(),
                    semiHeight: $(window).height() / 2
                }
                if (debug) console.log('windowInfo', windowInfo)

                let baseCSS = {
                    'transform': 'translate3d(0px,' + TRANSLATEY + 'px, 0px)',
                    'opacity': 0
                }

                let scrollElements = []

                $context.find('[data-scroll-animation]').each((i, el) => {
                    let $this = $(el)

                    scrollElements.push({
                        $context: $this,
                        height: $this.height(),
                        offsetTop: $this[0].offsetTop
                    })

                    $this.css({
                        'transform': 'translate3d(0px,' + TRANSLATEY + 'px, 0px)',
                        'opacity': 0
                    })
                })

                if (debug) console.log('scrollElements', scrollElements)

                $(window).on('scroll', (e) => {
                    let $this = $(e.currentTarget)
                    let windowScrollTop = $this.scrollTop() + windowInfo.height
                    if (debug) console.log(windowScrollTop)

                    scrollElements.map((infos, index) => {
                        let visibilityLimit = infos.offsetTop + windowInfo.semiHeight

                        let distanceToBeVisible = visibilityLimit - windowScrollTop

                        let opacityValue = 0
                        let translateValue = TRANSLATEY

                        if (distanceToBeVisible <= TRANSLATEY) {
                            if (distanceToBeVisible > 0) {
                                opacityValue = (1 - (distanceToBeVisible / TRANSLATEY)).toFixed(3)
                                translateValue = Math.round(distanceToBeVisible / 2)
                            } else {
                                opacityValue = 1
                                translateValue = 0

                                // Remove Element from array when fully visible
                                scrollElements.splice(index, 1)
                            }
                        } else {
                            opacityValue = 0
                            translateValue = TRANSLATEY
                        }

                        infos.$context.css({
                            'transform': 'translate3d(0px,' + translateValue + 'px, 0px)',
                            'opacity': opacityValue
                        })
                    })

                })
            }
        }
    }

    setDataComponent($context) {
        // recherche d'éventuels components et lancement de ceux ci
        $context.find('[data-component]').each((i, component) => {
            var $this = $(component)
            var componentName = $this.data('component')

            try {
                this.components[componentName]($this)
            }
            catch (e) {
                console.log('Controlleur non défini')
                console.log(e)
            }
        })
    }

    bindComponent(component, componentName) {
        this.components[componentName] = component
    }
}

// Start Core
var initCore = new Core($(document))



/*function ScrollDownAnimation($context) {
    const TRANSLATEY = 200

    let windowInfo = {
        height: $(window).height(),
        semiHeight: $(window).height() / 2
    }
    console.log('windowInfo', windowInfo)

    let contextInfo = {
        height: $context.height(),
        offsetTop: $context[0].offsetTop
    }
    console.log('contextInfo', contextInfo)

    $context.css({
        'transform': 'translate3d(0px,' + TRANSLATEY + 'px, 0px)',
        'opacity': 0
    })


    $(window).on('scroll', (e) => {
        let $this = $(e.currentTarget)
        let windowScrollTop = $this.scrollTop() + windowInfo.height

        let visibilityLimit = contextInfo.offsetTop + windowInfo.semiHeight

        let distanceToBeVisible = visibilityLimit - windowScrollTop
        console.log(distanceToBeVisible)

        let opacityValue = 0
        let translateValue = TRANSLATEY

        if (distanceToBeVisible <= TRANSLATEY) {
            if (distanceToBeVisible > 0) {
                opacityValue = (1 - (distanceToBeVisible / TRANSLATEY)).toFixed(3)
                translateValue = Math.round(distanceToBeVisible)
            } else {
                opacityValue = 1
                translateValue = 0

                $this.off('scroll')
            }
        } else {
            opacityValue = 0
            translateValue = TRANSLATEY
        }

        $context.css({
            'transform': 'translate3d(0px,' + translateValue + 'px, 0px)',
            'opacity': opacityValue
        })
    })

}*/





////////////////////////
// Custom components //
////////////////////////



// Main Nav
var MainNav = function ($context) {
    console.log('MainNav')
    let $nav = $context.find('#menu-menu-principal')
    let $mobileNavToggle = $context.find('#mobile-nav-toggle')
    let $mobileNavClose = $('#mobile-nav .mobile-nav-close')

    checkInvertNav($('#barba-wrapper'))
    checkLinkActive()

    Barba.Dispatcher.on('newPageReady', (currentStatus, oldStatus, container) => {
        checkInvertNav($(container))
        checkLinkActive()
    })

    function checkLinkActive() {
        let currentUrl = window.location.href

        $.each($nav.find('a'), (i, link) => {
            let $link = $(link)
            let linkUrl = $link.attr('href')

            if (currentUrl === linkUrl) {
                $link.addClass('active')
            } else {
                $link.removeClass('active')
            }
        })
    }

    function checkInvertNav($target) {
        if ($target.find('.page-activite').length) {
            $context.addClass('invert')
        } else {
            $context.removeClass('invert')
        }
    }

    $mobileNavToggle.on('click', function (e) {
        e.preventDefault()
        e.stopPropagation()

        $('body').addClass('mobile-nav-open')
    })
    $mobileNavClose.on('click', function (e) {
        e.preventDefault()
        e.stopPropagation()

        $('body').removeClass('mobile-nav-open')
    })
}

initCore.bindComponent(MainNav, 'MainNav')




var HomeSlider = function ($context) {
    console.log('HomeSlider')

    let $slides = $context.find('.home-slider__slides')
    let $bgs = $context.find('.home-slider__bgs')

    let listBg = []

    $.each($bgs.find('li'), (i, e) => {
        listBg.push($(e))
    })

    $slides.slick({
        dots: true,
        infinite: true,
        pauseOnHover: false,
        pauseOnFocus: false,
        slidesToShow: 1,
        rows: 0,
        arrows: true,
        appendArrows: $context.find('.home-slider--arrows'),
        appendDots: $context.find('.home-slider--dots'),
        fade: true,
        useCSS: false,
        useTransform: false,
        autoplay: true,
        autoplaySpeed: 3000
    })

    $slides.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        listBg[currentSlide].css('opacity', 0)
        listBg[nextSlide].css('opacity', 1)
    })
}

initCore.bindComponent(HomeSlider, 'HomeSlider')



var Share = function ($context) {
    console.log('Share')

    $context.find('.share--target').jsSocials({
        shareIn: "popup",
        shares: ["email", "twitter", "facebook", "linkedin", "pinterest"]
    });
}

initCore.bindComponent(Share, 'Share')


// Contact Form
var ContactForm = function ($context) {
    console.log('ContactForm')

    let $input = $context.find('input, textarea')

    $.each($input, function (i, el) {
        let $this = $(el)

        if ($this.val().length) {
            $this.addClass('filled')
        }
    })


    $input.on('change', function () {
        if ($(this).val().length) {
            $(this).addClass('filled')
        } else {
            $(this).removeClass('filled')
        }
    })
}

initCore.bindComponent(ContactForm, 'ContactForm')


// Pages activitées
var PagesActivities = function ($context) {
    console.log('PagesActivities')
    let $bannerImg = $context.find('.page-activite__banner--image')
    let $bannerText = $context.find('.page-activite__banner--text')
    let bannerHeight = $bannerImg.height()

    $(window).on('scroll', (e) => {
        let $this = $(e.currentTarget)
        let scrollTop = $this.scrollTop()
        let translateValue = Math.round(scrollTop / 4)
        let opacityValue = (1 - (scrollTop / bannerHeight)).toFixed(3)

        $bannerImg.css({
            'transform': 'translate3d(0px,' + translateValue + 'px, 0px)',
            'opacity': opacityValue
        })
        $bannerText.css({
            'transform': 'translate3d(0px,' + translateValue + 'px, 0px)',
            'opacity': opacityValue
        })
    })


}

initCore.bindComponent(PagesActivities, 'PagesActivities')


// Réalisation détail galerie
var RealisationGallery = function ($context) {
    console.log('RealisationGallery')

    if ($context.find('img').length) {
        const zooming = new Zooming({
            customSize: '100%'
        })

        zooming.listen('.realisation-detail__gallery img')
    }
}

initCore.bindComponent(RealisationGallery, 'RealisationGallery')


// Réalisation détail galerie
var RealisationList = function ($context) {
    console.log('RealisationList')

    if ($context.find('figure:not([data-bigger-link]) img').length) {
        const zooming = new Zooming({
            customSize: '100%',
            onBeforeOpen: function (target) {
                console.log(target)

                $(target).closest('figure').addClass('zoomed')

                // Now you have the target!
            },
            onClose: function (target) {
                console.log(target)

                $(target).closest('figure').removeClass('zoomed')
            }
        })

        zooming.listen('figure:not([data-bigger-link]) img')
    }
}

initCore.bindComponent(RealisationList, 'RealisationList')



// Modal cookies
var CookiesModal = function ($context) {
    console.log('CookiesModal')

    if (getCookie('CookiesModalVisibility') !== 'hidden') {
        $context.removeClass('hidden')

        $context.find('.btn-close').on('click', (e) => {
            e.preventDefault()
            e.stopPropagation()

            $context.addClass('hidden')

            setCookie('CookiesModalVisibility', 'hidden', 30)
        })
    }
}

initCore.bindComponent(CookiesModal, 'CookiesModal')


// Cookies helpers

function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}